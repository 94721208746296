.page-pagination {
    text-align: center;
    margin: 0 0 30px 0;

    p {
        margin-top: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $color-main;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }
}


//======================================================================================================
// Responsive 
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .page-pagination {     
        margin-top: 15px;
    } 

} 
