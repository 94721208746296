.breadcrumb {
    background-color: $color-bg--neutral;

    ul  {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 30px;
        padding: 17.5px 0;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-dark;
        position: relative;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.563rem;

        &:not(:last-child) {
            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/base-icons/ic-nav-chevron-bottom.svg);
                background-color: $color-dark;
                mask-repeat: no-repeat;
                top: unset;
                right: -25px;
                width: 22px;
                height: 22px;
                -webkit-mask-size: 22px;
                mask-size: 22px;
                transform: rotate(-90deg);
            }
        }

        &:first-child {
            padding-left: 0;
        }

        a {
            transition: all $duration ease-in-out;

            &:hover {
                color: $color-main;
                text-decoration: underline;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    // .breadcrumb {
    //     position: relative;

    //     ul {
    //         min-height: 60px;
    //     }

    //     li {
    //         min-height: 60px;
    //     } 
    // }

}
