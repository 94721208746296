.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 720px;
        background: $color-bg--image;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-white;
        padding: 50px 0;
    }

    &__title {
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        font-family: $font-family;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.563rem;
        margin: 20px 0 0;
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 580px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 470px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        
        &__image-wrapper {
            height: 400px;
        }
    }

}
