@use "sass:list"; // https://sass-lang.com/documentation/breaking-changes/slash-div

//=================================================================================================
// HTML base
//=================================================================================================
html {
    font: $font-style $font-variant $font-weight list.slash(#{$font-size}, #{$line-height}) $font-family;
    font-size: $font-size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//=================================================================================================
// Body base
//================================================================================================= 
body {
    font-size: $font-size--text;
    color: $color-text;
    background: $color-bg--body;

    &:not(.home) {
        footer {
            margin-top: 80px;
        }

        #resa {
            z-index: 2;
        }
    }
      
}

ol,
ul {
    list-style-type: none;
}

p,
ol,
ul,
dl,
table {
    margin: 0 0 0 0;
    padding: 0;
}


//=================================================================================================
// Remove extra margin for nested lists
//================================================================================================= 
ul li ul {
    margin-bottom: 0;
}

ol li ol {
    margin-bottom: 0;
}


//=================================================================================================
// Headings
//================================================================================================= 
h1,
h2,
h3,
h4,
h5 {
    font-family: $font-family--heading;
    font-weight: 700;
    color: $color-dark;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin: 25px 0 10px;
}

.wysiwyg h2 {
    margin: 0px 0px 30px;
    padding: 5px 0;
}


//=================================================================================================
// Heading individual styles
//================================================================================================= 
h1 {
    font-size: 3.438rem;
    line-height: 3.75rem;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        width: 75px;
        height: 75px;
        top: -20px;
        left: -45px;
        border-radius: 100px;
        background-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/home/motif_vert.png);
        background-repeat: repeat;
        z-index: -1;
    }
}

h2,
.gform_wrapper form h2.gsection_title,
.title-newsletter-h2 {
    font-size: 2.5rem;
    line-height: 2.813rem;
    padding-bottom: 10px;
    display: flex;
    align-items: flex-start;
    gap: 5px;

    &::before {
        position: relative;
        content: "";
        top: 8px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        background-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/home/bulle_titre_css.svg);
        background-repeat: no-repeat;
        z-index: 0;
        display: flex;
    }
}

h3 {
    font-size: 1.875rem;
    line-height: 2.188rem;
}

h4 {
    font-size: 1.563rem;
    line-height: 1.875rem;
}

h5 {
    font-size: 1.563rem;
}

h6 {
    line-height: 1.875rem;
}


//=================================================================================================
// Pictures styling
//=================================================================================================
//img:before {
//}


//=================================================================================================
// Link styling
//================================================================================================= 
a {
    color: inherit;
    text-decoration: none;
    transition: all $duration;

    &:hover,
    &:active,
    &:focus {
        color: $color-link--hover;
    }
}

.wysiwyg {
    a {
        color: $color-link;
    }
}


//=================================================================================================
// Highlight
//================================================================================================= 
mark {
    background: $color-highlight;
    padding: 0 0.2rem;
}


//=================================================================================================
// Blockquote
//================================================================================================= 
blockquote {
    font-size: 1.5rem;
    padding: 0 1.5rem;
    border-left: $borders--blockquote;
    margin: 0;

    cite {
        display: block;
        font-size: $font-size--text;
        text-align: right;
        margin-top: 0;
    }
}


//=================================================================================================
// Code block styling
//=================================================================================================
pre {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    tab-size: 2;
    padding: 1rem;
    border: 0;
    border-radius: $border-radius;
    margin: 0;

    code {
        font-family: $code-family;
        line-height: 1.2;
    }
}


//=================================================================================================
// Keyboard input
//================================================================================================= 
kbd {
    display: inline-block;
    line-height: 1.4;
    font-family: "Helvetica", Arial, sans-serif;
    font-size: $font-size--text-xsmall;
    color: #333;
    text-shadow: 0 1px 0 #fff;
    background-color: #f7f7f7;
    padding: .1em .6em;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    margin: 0 .1em;
}


//=================================================================================================
// Inline code styling
//================================================================================================= 
:not(pre)>code {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    padding: 0 0.2rem;
    border: $borders;
    border-radius: $border-radius;
}


//=================================================================================================
// Line break
//================================================================================================= 
hr {
    height: 0;
    border: 0;
    border-top: $borders;
}


//=================================================================================================
// Definition list
//================================================================================================= 
dt {
    font-weight: $font-weight-semibold;
}

dd {
    margin-bottom: .5rem;
}


//=================================================================================================
// Image/video wrapper
//================================================================================================= 
img {
    max-width: 100%;
    height: auto;
}

.image-wrapper {
    min-height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $color-light;
    overflow: hidden; 
}

.image-wrapper {
    video {
        margin-bottom: 10px;
    }
}


//=================================================================================================
// Gutter - Placement 
//================================================================================================= 
.no-gutter {
    padding-right: 0;
    padding-left: 0;
}

.content-right {
    text-align: right;
}

.container-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    body {
    
        &:not(.home) .footer {
            margin-top: 110px;

            &__container {
                &:before {
                    top: -130px;
                }
            }
        }
          
    }
}

// 640
@media screen and (max-width: $small) {
    body {
    
        &:not(.home) #resa {
            bottom: 15px;
        }
          
    }
    h1 {
        font-size: 2.375rem; //38px
        line-height: 2.5rem;
    }
    h2 {
        font-size: 1.750rem; //28px
        line-height: 2.125rem;

        &:before {
            top: 0px;
        }
    }
    h3 {
        font-size: 1.500rem; //24px
    }
    h4 {
        font-size: 1.188rem; //19px
    }
}
