.header {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;

    &__container {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 400px;
        overflow: hidden;
        transition: all $duration ease-in-out;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 410px;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 0px 0px 20px 20px;
            background-color: rgba(18, 26, 30, 0.9);
            z-index: -1;
            transition: width 1s ease-in-out;
        }

        &.overlay {
            width: 1170px;
        }
    }

    &__link {
        svg {
            fill: $color-white;
            margin: 55px 50px 0 50px;
            width: 250px;
            height: 55px;
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
        width: 0;
        display: flex;

        &:before {
            display: none;
        }
    }

    &.header-opacity {
        background-color:$color-dark;
    }

    &--fixed {
        z-index: 20;
        box-shadow: 0 0 17px rgba(0, 0, 0, 0.28),1px 1px 30px rgba(27,35,54,0.2);
        left: 0;
        padding: 0;
        position: fixed;
        right: 0;
        top: 0;
        height: 100px;
        background-color: rgba($color-dark, 0.9);

        .header {
            &__container {
                align-items: center;
            }

            &__content {
                display: flex;
                overflow: unset;
                position: relative;
                align-items: center;
                height: 100px;
                gap: 340px;
                width: 400px;

                &:before {
                    height: 0;
                    top: 100px;
                    background-color: $color-dark;
                    border-radius: 0;
                    left: calc(50% + -575px);
                    width: 2000vw;
                }

                &.overlay-fixed {
                    width: 400px;

                    &:before {
                        height: 300px;
                        box-shadow: 0 0 17px rgba(0, 0, 0, 0.28),1px 1px 30px rgba(27,35,54,0.2);
                    }
                }
            }

            &__link svg {
                margin: 0;
            }
        }

        .tools {
            z-index: 1;
            margin-top: 0;
            right: 0;

            &__item {
                &--accessibility {
                    &:hover, &:focus {
                        border-color: $color-second!important;
                    }
                }
    
                &--search {
                    width: 205px;
                }
            }
        }

        .nav-main {
            position: unset;

            &__open {
                display: flex;
            }

            &__container {
                display: none;
                position: absolute;
                left: 0;

                &.visible-container {
                    display: flex;
                }
            }

            .menu {
                margin: 30px 0 0 35px;
            }
        }
    }

    // .open-menu {
    //     opacity: 0;
    //     pointer-events: none;
    // }
}


//======================================================================================================
// Tools
//======================================================================================================

.tools {
    display: flex;
    margin-top: 40px;
    gap: 15px;
    margin-right: 18px;
    max-height: 40px;
    position: absolute;
    right: 15px;
    opacity: 1;
    transition: opacity 0.7s;

    &__item {
        margin: 0;
        max-height: 40px;
        gap: 10px;
        border: none;
        padding: 10px 0;
        transition: all $duration ease-in-out;

        &--translate {
            background-color: $color-second;
            border-color: $color-second;
            padding: 0;
            width: 40px;
            height: 40px;

            svg {
                width: 100%!important;
                height: 100%!important;
            }
        }

        &--search {
            background-color: $color-white;
            width: 205px;
        }

        &--accessibility {
            background-color: $color-third;
            border-color: $color-third;
            padding: 0;
            width: 40px;
            height: 40px;

            svg {
                fill: $color-dark;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }

    &__translate {
        position: relative;

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 60px;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 5px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-light;
            border-radius: $border-radius;
            transform: translateX(-50%);
        
            &.js-open {
                display: flex;
            }

            &::before {
                position: absolute;
                left: 50%;
                top: -6px;
                content: '';
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-light;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family--heading;
                font-size: toRem(16);
                font-weight: 400;
                line-height: toRem(20);
                color: $color-dark;
        
                &:hover, &:focus {
                    // color: $color-main;
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    // color: $color-main;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            position: absolute;
            left: 50%;
            bottom: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-second;
            border-radius: $border-radius--round;
            border: none;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-third;

                svg {
                    fill: $color-dark;
                }
            }
        }

        a.glink.gt-current-lang {
            font-weight: 700;
        }
    }
}

// Style for demosaisie only
body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }

        &::before {
            background: $color-bg--neutral;
        }
    }
}
// End Style for demosaisie only


// For OLD access bar
// #uci_link {
//     display: none;
// }


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__content {
            width: 365px;
        }

        &__link svg {
            margin: 40px 40px 0;
            width: 230px;
            height: 50px;
        }

        &--fixed {
            .header {
                &__content {
                    gap: 170px;

                    &:before {
                        left: calc(50% - 330px);
                    }

                    &.overlay-fixed {
                        &:before {
                            height: 275px!important;
                        }
                    }
                }
            }

            .nav-main {
                &__button {
                    width: 230px;
                }
            }

            .tools {
                gap: 10px;

                &__item {
                    &--demarches, &--search {
                        width: 200px;
                    }
                }
            }
        }
        
    }

    .tools {
        right: 10px;
        margin-right: 0;
    }
}


// 960
@media screen and (max-width: $medium) {

    .header {
        height: 90px;
        
        &__container {
            position: relative;
            justify-content: unset;
            align-items: center;
            gap: 175px;
            height: 100%;
            background: rgba($color-dark, 0.9);
            border-radius: 0px 0px 20px 20px;
        }

        &__content {
            display: flex;
            align-items: center;
            gap: 120px;
            overflow: unset;

            &:before {
                display: none;
            }
        }

        &__link svg {
            width: 200px;
            height: 40px;
            margin: 0;
            margin-top: 5px;
        }
    }

    .tools {
        gap: 10px;
        margin-top: 0;
        right: 80px;
        position: relative;
        
        &__item {
            background-color: $color-white;

            &--search {
                width: 40px;

                span {
                    display: none;
                }
            }

            &--accessibility {

                &:hover {
                    border-color: $color-second;
                }
            }

            // &--demarches {
            //     width: 160px;
            //     font-size: 0.875rem;
            //     line-height: 1.25rem;

            //     svg {
            //         display: none;
            //     }

            //     &:hover {
            //         color: $color-dark;
            //         background-color: $color-second;
            //     }
            // }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        height: 150px;

        &__container {
            flex-direction: row;
            gap: 15px;
        }

        &__content {
            justify-content: unset;
            align-items: unset;
            flex-direction: column;
            margin-left: 0;
            margin-top: 0;
            width: auto;
            gap: 0;
            position: unset;
            z-index: unset;
        }

        &__link svg {
            margin: 0;
            margin-left: 50px;
        }
    }

    .tools {
        gap: 15px;
        margin-left: -167px;
        margin-top: 60px;
        position: unset;
        z-index: unset;
    }
}
