#resa {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 85px;
    
    .thr-search-engine-dates, .thr-search-engine-type, .thr-search-engine-pers {
        min-width: 280px;
        height: 60px;
        margin: 0 15px 0 0!important;
        border-radius: 100px;
        background: white;
        border: none!important;
        transition: background $duration ease-in-out;

        &:focus, &:hover {
            background: $color-second;
            cursor: pointer;
        }
    }

    .thr-search-engine-dates {
        .form-group {
            max-height: 50px;
        }

        .ng-pristine {
            margin-top: -2px;
        }

        .thr-range-picker {
            padding: 0 0 0 2px !important;
            height: auto !important;
        }
    }

    .thr-search-engine {
        .form-group {
            background-color: transparent;
            padding: 5px 18px 24px 25px;
        }
        
        .thr-search-engine-main {
            flex-wrap: nowrap;
        }
    }

    .thr-btn-search {
        background-color: $color-third!important;
        color: $color-dark!important;
        max-width: 175px;
        min-width: 175px;
        height: 60px!important;
        border-radius: 100px;
        margin: 0!important;
        font-family: $font-family!important;
        font-size: 1rem!important;
        font-weight: 700!important;
        line-height: 1.25rem;
        letter-spacing: 0.8px;
        text-transform: uppercase;

        i {
            display: none;
        }

        &:hover, &:focus {
            background-color: $color-second!important;
        }
    }

    .ng-binding {
        color: $color-dark;
        font-family: $font-family;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.125rem;
        letter-spacing: 0.7px;
        text-transform: uppercase;

        i {
            right: 19px!important;
        }
    }

    .thr-range-picker, .thr-select-placeholder, .thr-select-placeholder {
        height: 60px !important;
        font-weight: 400!important;
        text-transform: lowercase!important;
        font-size: 0.875rem!important;
        line-height: 1.125rem!important;
        padding: 29px 18px 0 25px!important;

        i {
            right: 19px!important;
        }

        span {
            text-transform: initial!important;
        }
    }
    
    .thr-range-picker {
        position: relative;

        &:before {
            position: absolute;
            right: 1px;
            top: -8px;
            width: 15px;
            height: 15px;
            content: "";
            background-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/Pictos/ic_calendrier.svg);
            background-repeat: no-repeat;
            z-index: 0;
        }

        i {
            display: none;
        }

        span {
            &:before {
                position: relative;
                display: inline-flex;
                width: 8px;
                height: 8px;
                content: "";
                background-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/Pictos/ic_fleche_date.svg);
                background-repeat: no-repeat;
                z-index: 0;
            }
        }
    }

    .thr-select-options {
        top: 75px;
    }

    .thr .thr-search-engine .form-group .thr-disabled.thr-select {
        height: 60px !important;
        border-radius: 100px !important;

        .thr-select-placeholder {
            opacity: 0.4 !important;
        }
    }
}

.daterangepicker.dropdown-menu {
    margin-top: 10px!important;
    z-index: 1!important;
}

.thr-daterangepicker {
    td.active {
        background-color: $color-second!important;
        color: $color-dark!important;
    }

    .bottom {
        color: $color-dark!important;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    #resa {
        bottom: 45px;
        
        .thr-search-engine-dates, .thr-search-engine-type, .thr-search-engine-pers {
            min-width: 250px;
            margin: 0 10px 0 0!important;
        }

        .thr-search-engine {
            .form-group {
                background-color: transparent;
                padding: 5px 18px 24px 23px;
            }
            
            .thr-search-engine-main {
                flex-wrap: nowrap;
            }
        }

        .thr-btn-search {
            max-width: 160px;
            min-width: 160px;
        }

        .ng-binding {
            font-size: 0.75rem;
            line-height: 1rem;
            letter-spacing: 0.6px;
        }

        .thr-range-picker, .thr-select-placeholder, .thr-select-placeholder {
            font-size: 0.75rem!important;
            line-height: 1rem!important;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    #resa {
        bottom: 70px;

        .thr-range-picker, .thr-select-placeholder, .thr .thr-search-engine .form-group .thr-disabled.thr-select {
            height: inherit !important;
        }
        
        .thr-search-engine-dates, .thr-search-engine-type, .thr-search-engine-pers {
            min-width: 200px;
            max-width: 200px!important;
            height: 50px;
        }

        .thr-search-engine-pers {
            margin: 0!important;
        }

        .thr-search-engine {
            .form-group {
                padding: 0 18px 24px 14px;
            }
            
            .thr-search-engine-main {
                flex-wrap: wrap;
                justify-content: flex-start;
            }
        }

        .thr-btn-search {
            max-width: 620px;
            min-width: 620px;
            height: 40px!important;
            margin: 10px 0 0 !important;
        }

        .thr-range-picker {
            padding: 24px 18px 0 15px !important;

            &:before {
                bottom: 21px;
                right: -8px;
                top: unset;
                transform: unset;
            }
        }

        .thr-select-placeholder {
            padding: 23px 18px 0 13px !important;

            i {
                font-size: 1rem;
                right: 8px !important;
                top: unset !important;
                transform: unset !important;
                bottom: 14px;
            }
        }
    }

    .thr-select-options {
        top: 68px!important;
    }
}

// 640
@media screen and (max-width: $small) {
    #resa {
        bottom: -135px;
        
        .thr-search-engine-dates, .thr-search-engine-type, .thr-search-engine-pers {
            min-width: 300px;
            max-width: 300px!important;
            height: 50px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
        }

        .thr-search-engine {
            .thr-search-engine-main {
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 10px;
            }
        }

        .thr-range-picker::before {
            bottom: 27px;
            right: -4px;
        }

        .thr-select-placeholder i {
            bottom: 20px;
            font-size: 1rem;
            right: 13px !important;
        }

        .thr-btn-search {
            max-width: 300px;
            min-width: 300px;
            margin-top: 0 !important;
        }
    }

    .daterangepicker.dropdown-menu {
        margin-top: 0!important;
        z-index: 3001!important;
    }
}