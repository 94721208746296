@keyframes show {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.nav-main {
    position: relative;
    z-index: 10;

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // Main menu
    .menu {
        display: flex;
        flex-direction: column;
        margin: 35px 0 40px 35px;
        position: relative;
        z-index: 2;
        transition: $duration ease-out;

        &.animation {
            animation: show 1s ease-in-out 0s 1 normal none;
        }
        
        &__link {
            position: relative;
            display: flex;
            align-items: center;
            gap: 30px;
            color: $color-white;
            text-transform: uppercase;
            height: 69px;
            font-weight: 500;
            font-size: 1.563rem;
            line-height: 1.813rem;
            transition: all $duration ease-in-out;

            svg {
                width: 20px;
                height: 20px;
                fill: $color-third;
                transition: all $duration ease-in-out;
            }

            &.visible-item {
                margin-left: 25px;

                svg {
                    fill: $color-second;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 15px;
        right: unset;
        left: 0;
        z-index: -1;
        width: 1085px;
        padding-left: 385px;
        transition: opacity 0.55s ease-in-out;

        &.visible-sub {
            opacity: 1;
            pointer-events: auto;
            justify-content: center;
        }

        &__nav {
            width: 100%;
            column-count: 2;
            column-gap: 30px;
            height: 245px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }

        &__item {
            display: inline-flex;
            width: 300px;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: flex-start;
            gap: 10px;
            width: 100%;
            padding-bottom: 20px;
            color: $color-white;
            font-weight: $font-weight-medium;

            span {
                min-width: 6px;
                width: 6px;
                height: 6px;
                background-color: $color-third;
                border-radius: 50px;
                margin-top: 7px;
            }

            &:hover {
                span {
                    background-color: $color-second;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
    }

    &__button {
        position: relative;
        justify-content: center;
        align-items: center;
        gap: 10px;
        min-height: 40px;
        margin: 0;
        padding: 10px 0;
        width: 230px;
        background-color: $color-dark;
        border-color: $color-third;
        border-radius: 40px;
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 0.05em;
        line-height: 0;
        color: $color-white;
        text-transform: uppercase;
        transition: $duration;
        cursor: pointer;

        span {
            width: 16px;
            height: 1px;
            background-color: $color-white;
            transition: $duration;
            position: relative;
            display: flex;
            transition: all $duration ease-in-out;

            &:before, &:after {
                position: absolute;
                content: "";
                width: 16px;
                height: 1px;
                background-color: $color-white;
                left: 0;
                transition: all $duration ease-in-out;
            }

            &:before {
                top: -5px;
            }

            &:after {
                top: 5px;
            }

            &.span-close {
                height: 0;

                &:before {
                    top: 0;
                    transform: rotate(45deg);
                }
    
                &:after {
                    top: 0;
                    transform: rotate(-45deg);
                }
            }
        }

        &:hover, &:focus {
            color: $color-dark;
            background-color: $color-third;
            border-color: $color-third;

            span {
                background-color: $color-dark;

                &:before, &:after {
                    background-color: $color-dark;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close, .submenu__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu {
            margin: 35px 0 30px 30px;

            &__link {
                gap: 20px;
            }
        }

        .submenu {
            padding-left: 355px;
            width: 910px;

            &__item {
                width: 230px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-active-navmain {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-active-menu {
            
            // Container menu
            .nav-main__container {
                top: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: -100vh;
            left: 0;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-dark;
            padding: 40px 0;
            transition: $duration;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            display: flex;
            width: 100px;
            gap: 13px;
            font-size: 0.875rem;
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: -1px;
            display: block;
            width: 20px;
            height: 2px;
            background: $color-white;
            transition: $duration;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: $color-white;
                transition: $duration;
            }

            &:before {
                top: 7px;
            }

            &:after {
                top: -7px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: 2px solid $color-white;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
                transition: all $duration ease-in-out;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;
            margin: 0;
            position: unset;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
    
                &.menu-item-has-children {
    
                    &:focus-within .submenu, 
                    &:hover .submenu {
                        justify-content: flex-start;
                    }
    
                    // If sub menu open
                    &.js-active-submenu {   
                        .submenu {
                            left: 0;
                        }
                    }
                }
    
                &:hover, &:focus {
                    background-color: $color-bg--transparent;
    
                    .menu__link {
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-white;

                &.visible-item {
                    margin-left: 0;

                    svg {
                        color: $color-second;
                    }
                }

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            left: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: unset;
            row-gap: 30px;
            width: 100vw;
            height: 100vh;
            padding: 40px 0 60px 0;
            background-color: $color-dark;
            transition: $duration;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: 2px solid $color-white;
                border-radius: $border-radius--round;
                transform: rotate(180deg);
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-dark;
                    }
                }

                svg {
                    width: 15px;
                    height: 15px;
                    fill: $color-white;
                    transition: $duration;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                max-width: 100%;
                width: 100%;
                height: 100%;
                padding: 0 40px;
                overflow-y: auto;
            }

            &__item {
                margin: 10px auto;
                width: 100%;
            }

            &__link {
                color: $color-white;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .nav-main {
        position: unset;
        z-index: unset;
        
        &__button {
            font-size: 0;
            line-height: 0;
            width: 40px;
            height: 40px;
            margin: 15px 0 0 32px;

            span {
                left: 6px;
            }
        }
    }
}
