.scrollTopBtn {
    width: 45px;
    height: 45px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    transform: translateY(100px);
    z-index: 100;
    background: $color-third;
    border: none;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: all .5s ease;

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        width: 100%;
        height: 100%;
        transform: rotate(-90deg);
        fill: $color-dark;
    }

    &:hover,
    &:focus {
        background: $color-main;

        svg {
            fill: $color-white;
        }
    }
}