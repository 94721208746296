//======================================================================================================
// Breakpoints
//======================================================================================================
$large                                  : 1200px;
$medium                                 : 960px;
$small                                  : 640px;
$x-small                                : 320px;


//======================================================================================================
// Grids
//======================================================================================================
$gl-colCount                            : 12;
$gl-gridName                            : grid;
$gl-colName                             : col;
$gl-attributeName                       : class;
$gl-gutter                              : 30px;
$gl-gutter-vertical                     : 30px;

//Gutter responsive medium
$gl-gutter-md                           : 20px;
$gl-gutter-md-vertical                  : 20px;

//Gutter responsive small
$gl-gutter-sm                           : 20px;
$gl-gutter-sm-vertical                  : 20px;

//Gutter responsive x-small
$gl-gutter-xs                           : 20px;
$gl-gutter-xs-vertical                  : 20px;

$gl-mq-width                            : 'max-width';
$gl-mq-list: (
        lg: $large,
        md: $medium,
        sm: $small,
        xs: $x-small,
);


//======================================================================================================
// Global Colors
//======================================================================================================

// Colors
$red                                    : #C92C2C;
$redlight                               : #FFE6E6;
$orange                                 : #E8810B;
$orangelight                            : #fae6ce;
$green                                  : #46B450;
$greenlight                             : #E2FFEF;

// Main Colors
$color-main                             : #30626B;
$color-second                           : #61C4D6;
$color-third                            : #DADF2B;
$color-fourth                           : #DADF2B;

// Black & White
$color-white                            : #FFFFFF;
$color-light                            : #DFF3F7;
$color-gray                             : #B0E1EA;
$color-dark                             : #121A1E; 
$color-black                            : #000000;

// RGBA
$color-main--rgba                       : rgba(48, 98, 107, 0.8);
$color-second--rgba                     : rgba(97, 196, 214, 0.8);
$color-third--rgba                      : rgba(218, 222, 42, 0.8);
$color-black--rgba                      : rgba(0, 0, 0, 0.9);
$color-overlay--rgba                    : rgba(0, 0, 0, 0.8); // on overlay accessconfig

// Bloc infos
$color-error                            : $red;
$color-alert                            : $orange;
$color-success                          : $green;
$color-bg-error                         : $redlight;
$color-bg-alert                         : $orangelight;
$color-bg-success                       : $greenlight;
$color-flexible-alert                   : #E20000; // This color is only used on bloc-content--alert

// Text
$color-text                             : $color-dark;
$color-link                             : $color-main;
$color-link--hover                      : darken($color-link, 15%);
$color-highlight                        : #FFEEA8;

// Background
$color-bg--neutral                      : $color-light;
$color-bg--body                         : $color-white;
$color-bg--transparent                  : transparent;
$color-bg--image                        : $color-gray;
$color-bg--icons                        : $color-white;

// SVG
$color-svg--events                      : $color-gray; // single events
$color-svg--maps                        : $color-gray; // popup maps


//======================================================================================================
// Typography
//======================================================================================================

// Font Weight
$font-weight-thin                       : 200;
$font-weight-light                      : 300;
$font-weight-normal                     : 400;
$font-weight-medium                     : 500;
$font-weight-semibold                   : 600;
$font-weight-bold                       : 700;
$font-weight-extrabold                  : 800;
$font-weight-black                      : 900;

// Body
$font-size                              : 16px;
$font-style                             : normal;
$font-variant                           : normal;
$font-family                            : 'Karla', sans-serif;
$font-weight                            : $font-weight-normal;
$line-height                            : 1.563rem;

// Font Size
$font-size--text-medium                 : 1.250rem; // 20px
$font-size--text                        : 1.000rem; // 16px
$font-size--text-small                  : 0.875rem; // 14px
$font-size--text-xsmall                 : 0.813rem; // 13px

// Headings
$font-size--1                           : 3.125rem; // 50px
$font-size--2                           : 2.125rem; // 34px
$font-size--3                           : 1.750rem; // 28px
$font-size--4                           : 1.375rem; // 22px
$font-size--5                           : 1.125rem; // 18px
$font-size--6                           : 1.000rem; // 16px
// 640 (either here or in this file : _scaffolding.scss)
/*@media screen and (max-width: $small) {
   $font-size--1                        : 2.375rem; //38px
   $font-size--2                        : 1.750rem; //28px
   $font-size--3                        : 1.500rem; //24px
   $font-size--4                        : 1.188rem; //19px
}*/

$font-family--heading                   : 'IBM Plex Sans', sans-serif;
$font-weight--heading                   : $font-weight-bold;
$line-height--heading                   : 1.2;


//======================================================================================================
// Borders
//======================================================================================================
$border-width                           : 1px;
$border-width--blockquote               : 16px;
$border-style                           : solid;
$border-color                           : $color-fourth;
$border-color-text                      : $color-text;
$border-color-white                     : $color-white;
$border-radius                          : 5px;
$border-radius--round                   : 50%;
$borders                                : $border-width $border-style $border-color;
$borders--blockquote                    : $border-width--blockquote $border-style $border-color;


//======================================================================================================
// Box-shadow
//======================================================================================================
$shadow                                 : 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
$shadow--hover                          : 1px 1px 5px rgba(0,0,0,.25), 0px 0px 0px rgba(0,0,0,.1);


//======================================================================================================
// Transitions
//======================================================================================================
$duration                               : .5s;


//======================================================================================================
// Buttons
//======================================================================================================

// Font
$btn-font-family                        : $font-family;
$btn-font-weight                        : 400;
$btn-font-size                          : 1rem;
$btn-line-height                        : 1.25rem;
$btn-text-transform                     : uppercase;

// Colors
$btn-color                              : $color-dark;
$btn-color--hover                       : $color-dark;

// Backgrounds
$btn-bg                                 : $color-light;
$btn-bg--hover                          : $color-second;

// Borders
$btn-border-width                       : 1px;
$btn-border-style                       : solid;
$btn-border-radius                      : 30px;
$btn-border-color                       : transparent;
$btn-border-color--hover                : darken($btn-bg, 10%);

// Padding
$btn-padding                            : 10px 40px;


    //===============================================
    // Button secondary (used on document flexible)
    //===============================================

    // Colors
    $btn-second-color                   : $color-dark;
    $btn-second-color--hover            : $color-white;

    // Backgrounds
    $btn-second-bg                      : $color-third;
    $btn-second-bg--hover               : $color-dark;

    // Borders
    $btn-second-border-color            : transparent;
    $btn-second-border-color--hover     : transparent;

    // Padding
    $btn-second-padding                 : 10px 40px;


    //===============================================
    // Button variant 1 (if you need)
    //===============================================

    // Colors
    $btn-variant-color                  : $color-main;
    $btn-variant-color--hover           : $color-white;

    // Backgrounds
    $btn-variant-bg                     : $color-bg--neutral;
    $btn-variant-bg--hover              : $color-bg--transparent;

    // Borders
    $btn-variant-border-color           : $btn-variant-bg;
    $btn-variant-border-color--hover    : $color-white;


    //===============================================
    // Button category (on card.scss)
    //===============================================
    $btn-tax-border-radius              : 20px;


    //======================================================================================================
    // Buttons specific
    //======================================================================================================

    // Font
    $btn-spe-font-weight                : 500;
    $btn-spe-font-size                  : 0.875rem;
    $btn-spe-line-height                : 1.25rem;

    // Padding
    $btn-spe-padding                    : 5px 20px;

    // Borders
    $btn-spe-border-radius              : 50px;


    //===============================================
    // Button filter
    //===============================================

    // Colors
    $btn-filter-color                   : $color-dark;
    $btn-filter-color--hover            : $color-white;

    // Backgrounds
    $btn-filter-bg                      : $color-light;
    $btn-filter-bg--hover               : $color-dark;

    // Borders
    $btn-filter-border-color            : transparent;
    $btn-filter-border-color--hover     : transparent;


    //===============================================
    // Button toggler
    //===============================================

    // Colors
    $btn-toggler-color                  : $color-dark;
    $btn-toggler-color--hover           : $color-white;

    // Backgrounds
    $btn-toggler-bg                     : $color-bg--transparent;
    $btn-toggler-bg--hover              : $color-dark;

    // Borders
    $btn-toggler-border-color           : $color-dark;
    $btn-toggler-border-color--hover    : $color-dark;


    //===============================================
    // Button empty
    //===============================================

    // Colors
    $btn-empty-color                    : $color-dark;
    $btn-empty-color--hover             : $color-white;

    // Backgrounds
    $btn-empty-bg                       : $color-bg--transparent;
    $btn-empty-bg--hover                : $color-dark;

    // Borders
    $btn-empty-border-color             : $color-dark;
    $btn-empty-border-color--hover      : $color-dark;


    //===============================================
    // Button tag (button bottom page (transition or tag actu) style like button filter active)
    //===============================================

    // Colors
    $btn-tag-color                      : $color-dark;
    $btn-tag-color--hover               : $color-white;

    // Backgrounds
    $btn-tag-bg                         : $color-third;
    $btn-tag-bg--hover                  : $color-dark;

    // Borders
    $btn-tag-border-color               : transparent;
    $btn-tag-border-color--hover        : transparent;


    //===============================================
    // Button Privacy Policy
    //===============================================

    // Colors
    $btn-privacy-color                      : $color-text;
    $btn-privacy-color--hover               : $color-white;

    // Backgrounds
    $btn-privacy-bg                         : $color-white;
    $btn-privacy-bg--hover                  : $color-bg--transparent;

    // Borders
    $btn-privacy-border-color               : $btn-privacy-bg;
    $btn-privacy-border-color--hover        : $btn-privacy-bg;


//======================================================================================================
// Forms
//======================================================================================================
$forms                                  : ('[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea');
$btn                                    : ('.button, button, [type=submit], [type=reset], [type=button]');
$input-bg                               : $color-light;
$placeholder                            : darken($color-main, 20%);
$form-border-color                      : transparent;
$form-border-color--focus               : transparent;
$form-border                            : 0px solid $form-border-color;
$form-color-icon                        : $color-gray; // Couleur des icones dans les formulaires proposition events


//======================================================================================================
// Tables
//======================================================================================================
$stripes                                : #f8f8f8;
$caption                                : #ababab;

// Flexible table
$table-bg                               : $color-white;
$table-color                            : $color-text;
$table-border-width                     : 1px;
$table-border-color                     : $color-dark;
$table-even-bg                          : $color-white; // Pair background-color
$table-even-color                       : $color-text; // Pair color
$table-odd-bg                           : $color-light; // Impair background-color
$table-odd-color                        : $color-text; // Impair color
$table-head-bg                          : $color-dark;
$table-head-color                       : $color-white;
$table-head-border-color                : $color-white;


//======================================================================================================
// Code
//======================================================================================================
$code-color                             : $color-text;
$code-size                              : $font-size--text-small;
$code-family                            : Menlo, monospace;
$code-bg                                : $color-bg--transparent;


//======================================================================================================
// Card
//======================================================================================================
// Padding général des cards
$card--padding                          : 20px;

// Si le padding général est égal à 0
$card-content--padding                  : 0; // 20px si le padding général est égal à 0
$card-categorie--margin                 : $card--padding;
$card-squareHeight                      : 410px; // 460px si le padding général est égal à 0
$card-squareHeight-RWD                  : 460px; // responsive 1200 : 490px si le padding général est égal à 0

// Height de l'image des cards
$card-imageH                            : 180px; // 200px si le padding général est égal à 0

// Background Card
$color-bg--date-v1                      : $color-main;
$color-bg--date-v2                      : $color-main--rgba;
$color-bg--card                         : $color-white;
$color-bg-neutral--card                 : $color-bg--neutral;

// Text Card
$color--card                            : $color-text;
$color-second--card                     : $color-gray; // Date publication (single & card archive) & location


//======================================================================================================
// Taxo
//======================================================================================================
// Background Taxo
$color-bg--taxo                         : $color-gray;

// Text Taxo
$color--taxo                            : $color-text;


//======================================================================================================
// Image Caption
//======================================================================================================

// Background Caption
$color-bg-caption                       : $color-main--rgba;

// Text Caption
$color-caption                          : $color-white;


//======================================================================================================
// Swiper + Glightbox navigation
//======================================================================================================

// Arrow
$arrow-bg                               : $color-third;
$arrow-bg--hover                        : $color-second;
$arrow-color                            : $color-dark;
$arrow-color--hover                     : $color-white;


// Cross on Glightbox
$cross-bg                               : $color-white;
$cross-bg--hover                        : $color-third;
$cross-color                            : $color-main;
$cross-color--hover                     : $color-dark;


//======================================================================================================
// Accessibility a42
//======================================================================================================
$a42-background                         : $color-white;
$a42-card-background                    : $color-fourth;
$a42-card-border                        : $color-third;
$a42-card-input-background              : $color-white;
$a42-card-input-border                  : $color-main;
$a42-card-input-active                  : $color-main;
$a42-cross                              : $color-dark;


//======================================================================================================
// URL Shortner
//======================================================================================================

$urlShort : '/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/base-icons/';