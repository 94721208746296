.front-page {
    &__title {
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 4.375rem;
        line-height: 4.375rem;
        margin: 0;
        padding: 0;

        &:before {
            display: none;
        }

        &--small {
            margin: 0;
            padding: 0;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 1.875rem;
        line-height: 1.875rem;
    }

    &__button {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.563rem;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        gap: 15px;
        transition: all $duration ease-in-out;

        svg {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            transition: all $duration ease-in-out;
        }
    }

    &__section {
        position: relative;

        &:before, &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 495px;
            top: 0;
            left: 0;
        }

        &:before {
            z-index: -1;
            background: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/home/img_sejour.jpg);
            background-repeat: no-repeat;
        }

        &:after {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 34.38%, rgba(0, 0, 0, 0.1) 49.48%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
            z-index: 2;
        }

        &--navigation, &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none
        }
    }

    .slideshow {
        height: 720px;
        width: 100%;

        img, video {
            position: absolute;
            object-position: top;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 55.21%, rgba(0, 0, 0, 0.4) 81.25%, rgba(0, 0, 0, 0.4) 100%);
                z-index: 1;
            }
        }

        &__content {
            position: absolute;
            display: flex;
            align-items: baseline;
            gap: 15px;
            right: 30px;
            bottom: 75px;
            color: $color-white;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            gap: 10px;

            &--container {
                position: absolute;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 40px;
                display: flex;
                justify-content: center;
                box-sizing: border-box;
            }

            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                transition: .25s;
                background-color: $color-white;
                border-radius: 50px;
                opacity: 1;
    
                &:hover {
                    background-color: $color-third;
                }
    
                &-active {
                    background-color: $color-third;
                }
            }
        }
    
    }
}

//======================================================================================================
// Camping - Emplacements & Chalets
//======================================================================================================

.camping {
    margin: 85px 0 80px;

    &__container {
        position: relative;
    }

    &__header {
        width: 570px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__title {
        display: flex;

        svg {
            height: 40px;
            width: 35px;
            fill: $color-second;
            transform: rotate(90deg);
            margin-right: -7px;
            margin-top: -8px;
        }

        &--small {
            font-family: $font-family;
            font-size: 1.875rem;
            font-weight: 700;
            line-height: 2.188rem;
            transition: all $duration ease-in-out;
        }
    }

    &__description {
        margin: 5px 38px -5px 0;
    }

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        width: 570px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        &:nth-child(1) {
            margin-top: 30px;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 110px;
                height: 110px;
                left: -35px;
                top: -70px;
                background-repeat: repeat;
                background-size: 10px;
                z-index: 0;
                border-radius: 100px;
                background-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/home/motif_vert.png);
            }
        }

        &:nth-child(2) {
            margin-top: -105px;
        }

        &:hover {
            .camping__title--small {
                color: $color-main;
            }
        }
    }

    &__image {
        width: 100%;
        height: 320px;
        position: relative;

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-light;
            background-position: center;
            width: 100%;
            height: 320px;
            border-radius: 5px;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    &__intro {
        margin-top: 5px;
        font-family: $font-family;
        font-size: 1.125rem;
        font-style: italic;
        font-weight: 400;
        line-height: 1.563rem; 
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    position: relative;
    background-color: $color-light;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        mask-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/home/motif_bleu_clair.png);
        mask-repeat: repeat;
        background-color: rgba(97, 196, 214, 0.1);
    }

    &__blocs {
        display: flex;
        justify-content: center;
        gap: 80px;
        padding: 60px 0;
    }

    &__bloc {
        width: 160px;
        height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100px;
        background-color: $color-white;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.125rem;
        text-transform: uppercase;
        color: $color-dark;
        position: relative;
        transition: all $duration ease-in-out;    
        
        svg .svg-path {
            transition: all $duration ease-in-out;  
        }

        p {
            margin-top: 5px;
        }

        span {
            color: $color-main;
            transition: all $duration ease-in-out;
        }

        &:nth-child(1), &:nth-child(3), &:nth-child(4) {
            &:before {
                position: absolute;
                content: "";
                width: 50px;
                height: 50px;
                background-repeat: repeat;
                background-size: 5px;
                z-index: 0;
                border-radius: 100px;
            }
        }

        &:nth-child(1) {
            &::before {
                left: -15px;
                top: 15px;
                background-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/home/motif_bleu_clair.png);
            }
        }

        &:nth-child(3) {
            &::before {
                bottom: 20px;
                left: -35px;
                background-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/home/motif_bleu_fonce.png);
            }
        }

        &:nth-child(4) {
            &::before {
                right: -20px;
                top: 15px;
                background-image: url(/wp-content/themes/camping-paimpont/assets/src/images/sprite-images/home/motif_vert.png);
            }
        }

        &:hover {
            background-color: $color-second;

            span {
                color: $color-dark;
            }

            svg .svg-path {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Séjour
//======================================================================================================

.sejour {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    margin-top: 100px;

    &__title {
        font-family: $font-family--heading;
        font-size: 2.813rem;
        font-weight: 700;
        line-height: 3.125rem;
        color: $color-white;
        margin: 0;
        padding: 0;

        &:before {
            display: none;
        }
    }

    &__text {
        font-family: $font-family;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.563rem;
        margin: 25px 0 40px;
        width: 405px;
        color: $color-white;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 255px;
        height: 40px;
        background-color: $color-white;
        color: $color-dark;
        border-radius: 50px;
        font-family: $font-family;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            background-color: $color-second;
        }
    }
}

//======================================================================================================
// Horaires
//======================================================================================================

.horaires {
    color: $color-white;
    padding: 80px 50px 50px;
    background: $color-dark;
    border-radius: 0px 0px 20px 20px;
    width: 570px;
    position: relative;
    z-index: 1;

    &__title {
        color: $color-white;

        svg {
            width: 35px;
            height: 40px;
            transform: rotate(180deg);
            margin-left: -15px;
            margin-top: -15px;
            fill: $color-third;
        }
    }

    &__description {
        margin-left: 145px;
    }

    &__content {
        font-family: $font-family;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.563rem;
        margin: 50px 0;
    }

    &__button {
        background-color: $color-third;
        color: $color-dark;
        border-radius: 50px;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-align: center;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all $duration ease-in-out;

        &:hover {
            background-color: $color-white;
        }
    }

}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        &__title {
            font-size: 3.438rem;
            line-height: 3.75rem;
        }

        &__button {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        &__section::before {
            background-position: 40%;
        }
    }

    .home-slideshow {
        .slideshow {
            height: 580px;

            &__content {
                right: 20px;
            }

            &__pagination--container {
                bottom: 135px;
            }
        }
    }

    .camping {
        margin: 90px 0 60px;

        &__header {
            width: 460px;
            gap: 5px;
        }

        &__title {
            margin-top: 0;

            &--small {
                font-size: 1.75rem;
                line-height: 2.188rem;
            }
        }

        &__description {
            margin: 0 25px 0 0;
        }

        &__item {
            width: 460px;
        }

        &__image {
            height: 260px;
    
            &--notimg {
                height: 260px;
                min-height: 260px;
            }
        }

        &__date {
            left: 15px;
            bottom: 15px;
        }
    }

    .access {
        &__bloc {
            width: 140px;
            height: 140px;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 0.938rem;

            svg {
                width: 60px;
                height: 60px;
            }
        }
    }

    .sejour {
        &__title {
            font-size: 2.5rem;
            line-height: 2.813rem;
        }
    
        &__text {
            font-size: 1rem;
            width: 375px;
        }
    }

    .horaires {
        padding: 75px 40px 60px;
        width: 470px;

        &__description {
            margin: 5px 0 0 125px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        &__title {
            font-size: 2.813rem;
            line-height: 3.125rem;

            &--small {
                font-size: 1.5rem;
                line-height: 1.875rem;
            }
        }

        &__description {
            font-size: 1.25rem;
            line-height: 1.25rem;
        }

        &__section {
            &:before {
                height: 510px;
                background-size: auto 510px;
                background-position: 30%;
            }

            &:after {
                height: 510px;
            }
        }

        &__container {
            flex-direction: column;
            gap: 60px;
        }
    }

    .home-slideshow {
        position: relative;

        .slideshow {
            height: 470px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__content {
                bottom: 40px;
            }

            &__pagination {
                &--container {
                    bottom: 30px;
                }
            }
        }
    }

    .camping {
        margin: 80px 0 60px;

        &__header {
            width: 300px;
        }

        &__title {
            svg {
                width: 20px;
                height: 23px;
                margin-right: -5px;
                margin-top: -1px;
            }
        }

        &__item {
            width: 300px;

            &:nth-child(1) {
                &:before {
                    z-index: -1;
                }
            }

            &:nth-child(2) {
                margin-top: -75px;
            }
        }

        &__image {
            height: 170px;
    
            &--notimg {
                height: 170px;
                min-height: 170px;
            }
        }

        &__title--small {
            font-size: 1.5rem;
            line-height: 1.875rem;
        }

        &__date {
            left: 0;
            bottom: -55px;
            font-size: 1rem;
            line-height: 1.25rem;
            padding: 10px 25px;
        }
    }

    .access {
        &__blocs {
            justify-content: space-between;
            gap: 0;
        }
    
        &__bloc {
            width: 140px;
            height: 140px;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.125rem;

            svg {
                width: 60px;
                height: 60px;
            }
    
            &:nth-child(1) {
                &::before {
                    left: -25px;
                }
            }
    
            &:nth-child(3) {
                &::before {
                    bottom: unset;
                    left: -10px;
                    top: -20px;
                }
            }

            p {
                font-size: 0.875rem;
                line-height: 0.938rem;
            }
        }
    }

    .horaires {
        border-radius: 20px;
        padding: 40px 40px 60px;
        width: 100%;
        
        &__title svg {
            width: 20px;
            height: 22.5px;
            margin-left: -15px;
            margin-top: -10px;
        }

        &__description {
            margin-left: 100px;
        }

        &__content {
            margin: 30px 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .front-page__section {
        &:before {
            background-size: auto 535px;
            height: 535px;
        }

        &:after {
            height: 535px;
        }
    }

    .home-slideshow {
        .slideshow {
            height: 400px;

            &__content {
                bottom: 35px;
            }

            &__title {
                font-size: 1.25rem;
                line-height: 1.563rem;
                max-width: 235px;
            }

            &__pagination {

                &--container {
                    bottom: 110px;
                }

                .swiper-pagination-bullet {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .camping {
        margin: 195px 0 60px;

        &__list {
            flex-direction: column;
            margin-top: 20px;
            gap: 20px;
        }

        &__item {
            &:nth-child(1), &:nth-child(2) {
                margin-top: 0;
            }
        }
    }

    .access {
        &__blocs {
            gap: 20px;
            flex-wrap: wrap;
        }

        &__bloc {
            width: 140px;
            height: 140px;
            font-size: 0.875rem;
            line-height: 0.938rem;

            svg {
                width: 60px;
                height: 60px;
            }

            &:nth-child(3) {
                &::before {
                    display: none;
                }
            }
        }
    }

    .sejour__text {
        width: 100%;
    }

    .horaires {
        width: 320px;
        margin-left: -10px;
        padding: 30px 20px 40px;
        
        &__title svg {
            margin-left: -12px;
        }

        &__content {
            font-size: 1rem;
            line-height: 1.563rem;
            margin: 40px 0 30px;
        }

        &__button {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            letter-spacing: 0.7px;
        }
    }
}
