//======================================================================================================
// Quick Access
//======================================================================================================
.quick-access {
    position: fixed;
    top: 0;
    right: -910px;
    z-index: 15;
    display: block;
    height: 100%;
    width: 920px;
    background: $color-second;
    transition: all 0.75s;

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 820px;
        top: 50%;
        width: 230px;
        height: 50px;
        color: $color-white;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        transform: rotate(-90deg);

        span {
            margin-top: 10px;

            &::before,
            &::after {
                content: "\f343";
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 30px;
                color: $color-white;
                transition: all $duration;
            }

            &::after {
                left: 30px;
                right: inherit;
            }
        }

        svg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-second;
            transform: rotate(180deg);
        }
    }

    &__container {
        padding-left: 75px;
        height: 100%;

        h2 {
            color: $color-white;
            border: 0;
            margin: 90px 0 50px;
            text-transform: uppercase;
        }

        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 560px;
            max-height: 560px;

            li {
                width: calc(50% - 30px);
                margin-bottom: 15px;

                a {
                    display: block;
                    width: 100%;
                    color: $color-white;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &.open {
        right: 0;

        .quick-access__title {
            span {
                &::before,
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .quick-access {
        width: 890px;
        right: -880px;

        &__title {
            right: 790px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .quick-access {
        display: none;
    }

}