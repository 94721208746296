.footer {
    margin: 50px auto 10px;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 60px;
        border-bottom: 1px solid $color-dark;
    }
    
    &__infos {
        width: 400px;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 1.563rem;
        line-height: 1.875rem;
        margin-bottom: 15px;
    }

    &__phone {
        font-weight: 700;

        &:hover {
            color: $color-main;
        }
    }

    &__dpt {
        margin-bottom: 15px;
    }

    &__horaires {
        margin-top: 30px;
    }

    &__links {
        display: flex;
        align-items: flex-end;
        gap: 215px;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 29px;
    }

    &__button {
        &:first-child {
            svg {
                background-color: $color-third;
            }

            &:hover {
                svg {
                    background-color: $color-dark;
                    fill: $color-white;
                }
            }
        }

        &:last-child {
            svg {
                background-color: $color-second;
            }

            &:hover {
                svg {
                    background-color: $color-light;
                }
            }
        }
    }

    &__reseaux {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 30px;

        a {
            svg {
                background-color: $color-main;
                fill: $color-white;
            }

            &:hover {
                svg {
                    background-color: $color-dark;
                    fill: $color-white;
                }
            }
        }
    }
    
    &__menu {
        padding: 32.5px 0;

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;

            a {
                &:hover {
                    color: $color-main;
                }
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================


// 1200
@media screen and (max-width: $large) {

    .footer {
        margin: 40px auto 10px;

        &__top {
            padding-bottom: 55px;
        }

        &__text {
            line-height: 20px;
            font-size: 0.875rem;
        }

        &__dpt {
            line-height: 20px;
            font-size: 0.875rem;
            margin: 0 0 12px 0;
        }

        &__phone {
            font-size: 0.875rem;
            list-style: 1.25rem;
        }

        &__horaires {
            margin-top: 25px;
        }

        &__links {
            gap: 145px;
            margin-bottom: 5px;
        }

        &__reseaux {
            gap: 25px;
        }

        &__buttons {
            gap: 25px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        margin: 100px auto 0;

        &__container {
            &:before {
                top: -180px;
            }
        }

        &__top {
            padding-bottom: 35px;
            align-items: flex-start;
        }

        &__infos {
            width: 285px;
        }

        &__links {
            flex-direction: column;
            gap: 20px;
        }

        &__buttons {
            gap: 20px;
        }

        &__reseaux {
            flex-direction: row;
            gap: 10px;

            a p {
                display: none;
            }
        }

        &__menu ul {
            justify-content: unset;
            gap: 20px 50px;
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        &__top {
            flex-direction: column;
            justify-content: center;
            gap: 27px;
            padding-bottom: 60px;
        }

        &__infos {
            width: 100%;
        }

        &__title, &__text {
            text-align: center;
        }

        &__dpt {
            text-align: center;
        }

        &__phone {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &__links {
            margin: auto;
        }

        &__buttons {
            align-items: center;
            gap: 15px;
        }

        &__reseaux {
            justify-content: center;
            width: 100%;
        }

        &__menu ul {
            justify-content: center;
            gap: 15px 50px;
        }
    }

}